<template>
    <el-dialog :visible.sync="visible" width="320px">
        <div class="dialog-title">提示</div>
        <div class="dialog-content">您的点数已用完，请前往购买VIP或点数</div>
        <div class="button-group flex-row juet-between">
            <div class="flex"></div>
            <el-button size="mini" @click="visible = false">取消</el-button>
            <el-button size="mini" type="primary" @click="$router.push('/vip');visible = false">开通VIP</el-button>
            <el-button size="mini" type="success" @click="$router.push('/task?id=' + task);visible = false">购买点数</el-button>
            <div class="flex"></div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        visible: false,
        task: null,
    },
}
</script>

<style lang="scss">
.dialog-title{
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}
.dialog-content {
    font-size: 12px;
    color: #333;
    text-align: center;
    margin-bottom: 25px;
}

.el-button--mini {
    padding: 7px 15px !important;
}
</style>