<template>
    <div class="header ">
        <div class="mod-head">
            <div class="inner">
                <div class="bd">
                    <div class="logo flex-row" @click="$router.push('/')" style="cursor: pointer;">
                        <a >
                            <img src="../assets/image/public/logo.png" alt="" width="80" height="80" />
                        </a>
                        <div style="margin: auto;">
                            <div class="title">AI创作喵<span style="color: #ff8a00;font-size: 16px;"> · 媒体创作</span></div>
                            <div class="subtitle">AI智能创作在线工具</div>
                        </div>

                    </div>
                    <div class="user" v-if="$store.state.userinfo.id">
                        <div class="card flex-row">
                            <div class="icon-vip" v-if="$store.state.userinfo.isVip == 1"></div>
                            <img :src="$store.state.userinfo.headImg.replace('http://www.kjuyt.cn:8082/','/api/')" alt="" width="42" height="42" style="border-radius: 21px;" />
                            <div class="name">
                                <div>ID：{{ $store.state.userinfo.id }}</div>
                                <div style="font-size: 12px;">{{ $store.state.userinfo.isVip == 1 ? "会员到期：" +
                                    $store.state.userinfo.expTime : "普通会员"}}</div>
                            </div>
                            <div class="popup">
                                <!-- <a href="/pay/">账户充值</a> -->
                                <a @click="logout()" style="cursor: pointer;">退出登录</a>
                            </div>
                        </div>
                    </div>
                    <div class="user" v-if="!$store.state.userinfo.id">
                        <!-- <ul>
                            <li><a href="javascript:;" onclick="USER.login();" rel="nofollow">登录</a></li>
                            <li><a href="javascript:;" onclick="USER.login('reg');" rel="nofollow">注册</a></li>
                        </ul> -->
                        <div class="login-button" @click="onLoginDialog" style="cursor: pointer;">
                            登录/注册
                        </div>
                    </div>
                    <div class="menu">
                        <ul>
                            <li :class="active == 'index' ? 'active' : ''"><a href="/">AI写作</a></li>
                            <li :class="active == 'audio' ? 'active' : ''"><a href="/audio/">AI配音</a></li>
                            <li :class="active == 'image' ? 'active' : ''"><a href="/image/">AI图像</a></li>
                            <li :class="active == 'extract' ? 'active' : ''"><a href="/extract/">文案提取</a></li>
                            <li :class="active == 'creation' ? 'active' : ''"><a style="cursor: pointer;" @click="onCreationList">我的创作</a></li>
                            <li><a href="https://www.chuangzuomiao.com/" target="_blank">教程资讯</a></li>
                            <li :class="active == 'vip' ? 'active' : ''"><a style="cursor: pointer;" @click="onVip">会员中心</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        active: ''
    },
    created() {
        console.log(this.$store.state.userinfo);
    },
    methods: {
        onLoginDialog() {
            this.$install('LoginDialog')
        },

        logout() {
            this.$axios.post("/web/consumer/logout?token=" + localStorage.token)
                .then(res => {
                    this.$store.commit("userinfo", null);
                    localStorage.setItem(process.env.VUE_APP_LOCAL_TOKEN_KEY, null);
                    this.$router.go(0)
                })
        },

        onCreationList() {
            if (!localStorage.token || localStorage.token == 'null') {
                this.$install('LoginDialog')
                return
            }
            this.$router.push("/creation/list/")
        },

        onVip() {
            if (!localStorage.token || localStorage.token == 'null') {
                this.$install('LoginDialog')
                return
            }
            this.$router.push("/vip")
        }
    }
};
</script>

<style lang="scss">
.user {
    width: auto !important;
}

.logo {
    .title {
        font-size: 22px !important;
        color: #000 !important;
        margin-right: 10px;
        margin-bottom: 5px;
    }

    .subtitle {
        font-size: 12px !important;
        color: #666 !important;
    }
}

.login-button {
    background: #0557E9;
    border-radius: 5px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 25px;
    padding-right: 25px;

    &:active {
        opacity: 0.7;
    }
}
// .mod-head .bd .menu li {
//     font-size: 18px !important;
// }
.mod-head .bd .menu li a {
    padding: 0 20px !important;
}
// .mod-head .bd .user .name::after {
//     position: relative;
//     bottom: -3px;
//     display: inline-block;
//     content: '';
//     width: 0;
//     height: 0;
//     vertical-align: middle;
//     border: 6px solid transparent;
//     border-top: 6px solid #dbdbdb
// }

.card {
    .name {
        margin-left: 10px;
        margin-right: 15px;
        line-height: 20px !important;
    }
}

.icon-vip {
    position: absolute;
    background-image: url("../assets/vip.png");
    width: 20px;
    height: 20px;
    top: 45px;
    left: 25px;
    background-repeat: no-repeat;
    background-size: 20px auto
}

// .mod-head .bd .user .popup {
//     left: -50px !important;
//     top: 70px !important;
//     width: 180px !important;
// }
</style>