import Vue from "vue";
import Router from "vue-router";
import axios from "./axios";

// fix MavigationDuplicated issue
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

// 所有页面以及路径
const routes = [
    // 首页
    // {
    //     path: "/",
    //     redirect: "/"
    // },
    {
        path: '/',
        name: '首页',
        component: () => import('../views/Index.vue'),
        
        meta:{
            point:false,
            title:"AI创作喵-AI智能在线写作,一键生成原创文案",
            keywords:"AI写作, 文案创作工具,文案提取,伪原创改写,办公学习助手",
            description:"AI创作喵在线创作工具是一个专注于自媒体创作视频、文案、配音、图片生成为一体的平台，提供强大的AI写作工具,视频文案提取,图片文字提取,AI文字配音,AI文生图,AI图生图等服务，助您快速提升创作效率。"
        }
    },
    // {
    //     path: '/audio',
    //     name: 'audio',
    //     component: () => import('../views/Audio.vue'),
    // },
    {
        path: '/writing/:id',
        name: 'AI创作',
        component: () => import('../views/Writingai.vue'),
        meta:{
            point:true
        }
    },
    {
        path: '/extract',
        name: '文案提取',
        component: () => import('../views/Writing.vue'),
        meta:{
            point:false,
            title:"在线链接视频图片精准提取-AI创作喵",
            keywords:"视频文案提取,图片文字提取,文案提取神器",
            description:"AI创作喵是短视频创作者的学习平台，为您提供视频文案提取、中英文字幕提取、图片文字提取等提取工具。"
        }
    },
    {
        path: '/creation/list',
        name: '我的创作',
        component: () => import('../views/CreationList.vue'),
        meta:{
            point:false,
            title:"我的创作-AI创作喵"
        }
    },
    {
        path: '/creation/detail',
        name: '我的创作',
        component: () => import('../views/CreationDetail.vue'),
        meta:{
            point:false,
            title:"我的创作-AI创作喵"
        }
    },
    {
        path: '/audio',
        name: 'ai配音',
        component: () => import('../views/Audio.vue'),
        meta:{
            point:false,
            title:"AI文字配音-在线专业配音网站-AI创作喵",
            keywords:"AI配音网站,在线文字转语音,短视频配音,语音合成,AI朗读器",
            description:"AI创作喵在线AI配音作为短视频创作者首选工具，为您提供在线文字转语音、AI文字配音、短视频配音、解说配音、AI朗读配音、语音合成等专业配音服务。"
        }
    },
    {
        path: '/image',
        name: 'ai绘画',
        component: () => import('../views/Image.vue'),
        meta:{
            point:false,
            title:"AI图像-AI在线作图工具集-AI创作喵",
            keywords:"AI作图,AIGC创作,AI素材,AI商品图,AI智能绘画",
            description:"AI创作喵智能设计AI图像在线工具，为您提供AI文生图、AI图生图、智能抠图、AI素材、照片转漫画风、AI商品图、图片变清晰、老照片修复、AI场景图、AI消除、AI扩图、图片批量处理等全方位AI作图工具，可轻松创作您想要的图片。"
        }
    },
    {
        path: '/draw',
        name: 'ai绘画',
        component: () => import('../views/Draw.vue'),
        meta:{
            point:false,
            title:"AI绘画-人工智能在线生成图片-AI创作喵",
            keywords:"AI绘画,文生图,图生图,AI在线生成图片",
            description:"AI创作喵在线AI绘画生成工具拥有强大的绘画能力，为您提供在线AI绘画、文生图、图生图、动漫绘画、二次元绘画、国风绘画等，可精准画出您所描述的图片细节内容。"
        }
    },
    {
        path: '/repaint',
        name: '图生图',
        component: () => import('../views/I2i.vue'),
        meta:{
            point:false,
            title:"AI图生图-在线智能绘画高清图片-AI创作喵",
            keywords:"AI图生图,AI绘画,一键生图,绘画助手",
            description:"AI创作喵在线AI图生图工具，拥有全新AI大模型算法，提供AI图生图、AI文生图、日漫风绘画、古风绘画、3D风绘画、复古绘画等AI绘画一键生图助手，快速创作比原图还精美的风格图片。"
        }
    },
    {
        path: '/matting',
        name: '智能抠图',
        component: () => import('../views/Matting.vue'),
        meta:{
            point:false,
            title:"AI智能抠图-在线快速抠图神器-AI创作喵",
            keywords:"AI智能抠图,人像抠图,商品抠图,一键抠图",
            description:"AI创作喵在线AI智能抠图工具，为您提供通用抠图、人像抠图、商品抠图、背景抠图等多种抠除方式，新手小白无需ps一键快速抠图。"
        }
    },
    {
        path: '/drawDetail',
        name: '绘画详情',
        component: () => import('../views/DrawDetail.vue'),
        meta:{
            point:false,
            title:"AI文字配音-在线专业配音网站-AI创作喵",
            keywords:"AI配音网站,在线文字转语音,短视频配音,语音合成,AI朗读器",
            description:"AI创作喵在线AI配音作为短视频创作者首选工具，为您提供在线文字转语音、AI文字配音、短视频配音、解说配音、AI朗读配音、语音合成等专业配音服务。"
        }
    },
    {
        path: '/task',
        name: '购买次数',
        component: () => import('../views/Task.vue'),
        meta:{
            point:false,
            title:"AI文字配音-在线专业配音网站-AI创作喵",
            keywords:"AI配音网站,在线文字转语音,短视频配音,语音合成,AI朗读器",
            description:"AI创作喵在线AI配音作为短视频创作者首选工具，为您提供在线文字转语音、AI文字配音、短视频配音、解说配音、AI朗读配音、语音合成等专业配音服务。"
        }
    },
    {
        path: '/pic',
        name: 'ai配音',
        component: () => import('../views/PicTest.vue'),
        meta:{
            point:false,
            title:"AI创作喵",
            keywords:"AI创作喵",
            description:"AI创作喵"
        }
    },
    // {
    //     path: '/member/password/',
    //     name: '/member/password/',
    //     component: () => import('../views/Password.vue'),
    //     meta:{
    //         point:false,
    //         title:"创作喵",
    //         keywords:"占位",
    //         description:"占位"
    //     }
    // },
    // {
    //     path: '/pay/',
    //     name: '/pay/',
    //     component: () => import('../views/Pay.vue'),
    //     meta:{
    //         point:false,
    //         title:"创作喵",
    //         keywords:"占位",
    //         description:"占位"
    //     }
    // },
    {
        path: '/vip',
        name: '会员中心',
        component: () => import('../views/Vip.vue'),
        meta:{
            point:false,
            title:"会员中心-AI创作喵"
        }
    },

    // demo页面： drag/drop
    // {
    //     path: '/demo/drag',
    //     component: () =>
    //         import ('@/views/TestDrag')
    // },

    // 404页面
    // {
    //     path: "*",
    //     redirect: "/404",
    // },
    // {
    //     path: "/404",
    //     component: () =>
    //         import("@/views/NotFound"),
    // },
];

// 创建路由
const router = new Router({
    mode: "history",
    base: process.env.VUE_APP_DEPLOY_PATH,
    routes: routes,
});

// 路由拦截器
router.beforeEach((to, from, next) => {
    // var publicPaths = ["/login", "/404", '/demo/', '/index'];

    // // 非公共页面
    // if (publicPaths.indexOf(to.path) < 0) {
    //     axios.post("/pub/tokenInfo", {}, {
    //         __noloading: true
    //     }).then((res) => {
    //         res = res.data;
    //         if (res.error != 0) {
    //             next("/login?backurl=" + encodeURIComponent(to.path));
    //             return;
    //         }

    //         router.app.$options.store.commit("userinfo", res.data);
    //         next();
    //     });
    //     return;
    // }

    console.log(to);
    // 修改seo 
    if (to.meta && to.meta.title) {
        document.title = to.meta.title
        var keywords = document.getElementsByName("keywords");
        if(keywords.length > 0) {
            keywords[0].remove
        }
        var description = document.getElementsByName("description");
        if(description.length > 0) {
            description[0].remove
        }
        let meta = ''
        if (to.meta.keywords) {
            meta += `<meta name="keywords" content="${to.meta.keywords}">`
        }
        if (to.meta.description) {
            meta += `<meta name="description" content="${to.meta.description}">`
        }
        // 设置或更新meta标签
        document.getElementsByTagName('head')[0].innerHTML += meta
    }
    // if(to.meta && to.meta.point) {
        
    // } 


    // 公共页面
    if (!localStorage.token || localStorage.token == 'null') {
        next();
        return
    }
    axios.post("/web/consumer/selConsumerById?token=" + localStorage.token, {}, {
        __noloading: true
    }).then((res) => {
        res = res.data;
        if (res.status == 200) {
            router.app.$options.store.commit("userinfo", res.data);
        }
        if (res.status == 600) {
            localStorage.setItem(process.env.VUE_APP_LOCAL_TOKEN_KEY, null);
        }

        next();
    });
    next();
});

export default router;
